import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ToastrService } from '../services/toastr.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { API_ROUTES } from './api-routes';

@Injectable()
export class HttpService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService
  ) {
    if (environment.baseUrl && !environment.baseUrl.endsWith("/")) {
      environment.baseUrl = environment.baseUrl + '/'
    }
  }
  listAll(url: string) {
    if (url.startsWith("/")) {
      url = url.substring(1, url.length)
    }
    const reqUrl = environment.baseUrl + url;
    return this.http.get(reqUrl,)
  }

  list(url: string, query: any) {
    if (url.startsWith("/")) {
      url = url.substring(1, url.length)
    }
    const reqUrl = environment.baseUrl + url;
    return this.http.get(reqUrl, this.getQuery(query))
      .pipe(
        map((data: any) => data)
      );
  }

  read(url: string, id: number) {
    if (url.startsWith("/")) {
      url = url.substring(1, url.length)
    }
    const reqUrl = environment.baseUrl + url;
    return this.http.get(reqUrl, this.getQuery({ id, page: { start: 1, limit: 1 } }))
      .pipe(
        map((data: any) => data)
      );
  }

  post(url: string, body: any) {
    if (url.startsWith("/")) {
      url = url.substring(1, url.length)
    }

    const reqUrl = environment.baseUrl + url;
    return this.http.post(reqUrl, body)
      .pipe(
        map((data: any) => data)
      );
  }
  middleware(url: string, body: any) {  
    return this.http.post(url, body)
      .pipe(
        map((data: any) => data)
      );
  }

  update(url: string, body: any) {
    if (url.startsWith("/")) {
      url = url.substring(1, url.length)
    }
    const reqUrl = environment.baseUrl + url;
    return this.http.put(reqUrl, body)
      .pipe(
        map((data: any) => data)
      );
  }

  view(url: string, id: number) {
    if (url.startsWith("/")) {
      url = url.substring(1, url.length)
    }
    const reqUrl = environment.baseUrl + url;
    return this.http.get(reqUrl + '/' + id)
      .pipe(
        map((data: any) => data)
      );
  }

  delete(url: string, id: number) {
    if (url.startsWith("/")) {
      url = url.substring(1, url.length)
    }
    const reqUrl = environment.baseUrl + url;
    return this.http.delete(reqUrl + '/' + id)
      .pipe(
        map((data: any) => data)
      );
  }

  navigate(path: Array<string>, extras?) {
    this.router.navigate(path, extras)
  }


  getQuery(query: any = {}) {
    // if (!query.page) {
    //   query.page = { start: 1, limit: 100 }
    // }
    query = Object.keys(query).map((key) => {
      if (key == 'pages') {
        return `_page=${query[key].start}&_limit=${query[key].limit}`
      } else {
        if (query[key] instanceof Array) {
          return key + '=' + query[key].join("&" + key + "=")
        } else {
          return `${key}=${query[key]}`
        }
      }
    }).join('&')
    const opts = { params: new HttpParams({ fromString: query }) };
    return opts
  }
  // upload(file) {
  //   return this.http.post(environment.baseUrl + API_ROUTES.LAND_FILE_UPLOAD, file);
  // }
}
